import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User, Role } from '../models';



@Injectable({providedIn: 'root'})
export class AuthenticationService {
    //public
    public currentUser: Observable<any>;

    //private
    public currentUserSubject: BehaviorSubject<any>;
    public tenantApiUrl: any = environment.fullTenantApiUrl;
    public authTenantApiUrl: any = environment.authTenantApiUrl;

    /**
     *
     * @param {HttpClient} _http
     * @param {ToastrService} _toastrService
     */
    constructor(private _http: HttpClient) {
        let currentUser: User | null = null;

        if (typeof window !== 'undefined' && localStorage) {
            const storedUser = localStorage.getItem('currentUser');
            if (storedUser) {
                try {
                    currentUser = JSON.parse(storedUser); // Safely parse JSON
                } catch (e) {
                    console.error('Invalid JSON in localStorage for currentUser:', e);
                }
            }
        }

        this.currentUserSubject = new BehaviorSubject<User|null>(currentUser);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    // getter: currentUserValue
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    /**
     *  Confirms if user is admin
     */
    get isAdmin() {
        return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
    }

    /**
     *  Confirms if user is client
     */
    get isClient() {
        return this.currentUser && this.currentUserSubject.value.role === Role.Client;
    }

    /**
     * User login
     *
     * @param email
     * @param password
     * @returns user
     */

    checkDomain(domain: string) {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this._http
            .post<any>(`${environment.centralApi}/accounts/check-domain`, {domain}, {headers})
            .pipe(
                map(data => {
                    // login successful if there's a jwt token in the response
                    if (data && data.status_code == 200) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('segment', JSON.stringify(data.data.domain));

                        // Display welcome toast!
                        setTimeout(() => {
                            // this._toastrService.success(
                            //     'You Will Redirected To ' +
                            //     data.data.domain +
                            //     ' Now you can start to explore. Enjoy! 🎉',
                            //     '👋 Welcome, To Membire',
                            //     {toastClass: 'toast ngx-toastr', closeButton: true}
                            // );
                        }, 2500);

                        // notify
                    }
                    return data;
                })
            );
    }

    registerAsTenant(form: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this._http
            .post<any>(`${environment.centralApi}/accounts/register`, form, {headers})
            .pipe(
                map(data => {
                    // login successful if there's a jwt token in the response
                    if (data && data.status_code == 200) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('segment', JSON.stringify(data.data.domain));

                        // Display welcome toast!
                        setTimeout(() => {
                            // this._toastrService.success(
                            //     'You Will Redirected To ' +
                            //     data.data.domain +
                            //     ' Now you can start to explore. Enjoy! 🎉',
                            //     '👋 Welcome, To Membire',
                            //     {toastClass: 'toast ngx-toastr', closeButton: true}
                            // );
                        }, 2500);

                        // notify
                    }
                    return data;
                }));
    }
    register(form: any) {
        return this._http
            .post<any>(`${this.tenantApiUrl}/v1/api/lms/register`, form)
            .pipe(
                map(data => {
                    // login successful if there's a jwt token in the response
                    if (data && data.status_code == 200) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('segment', JSON.stringify(data.data.domain));

                        // Display welcome toast!
                        setTimeout(() => {
                            // this._toastrService.success(
                            //     'You Will Redirected To ' +
                            //     data.data.domain +
                            //     ' Now you can start to explore. Enjoy! 🎉',
                            //     '👋 Welcome, To Membire',
                            //     {toastClass: 'toast ngx-toastr', closeButton: true}
                            // );
                        }, 2500);

                        // notify
                    }
                    return data;
                }));
    }
    loginWithMobile(mobile: string) {
        return this._http
            .post<any>(this.tenantApiUrl + `/lms/send-opt`, {mobile})
            .pipe(
                map(user => {
                    return user;
                })
            );
    }
    verifyOtp(opt: any) {
        return this._http
            .post<any>(this.authTenantApiUrl + `/email-login`, {opt})
            .pipe(
                map(user => {
                    // login successful if there's a jwt token in the response
                    if (user && user.data.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user.data));

                        // notify
                        this.currentUserSubject.next(user.data);
                    }

                    return user.data;
                })
            );
    }

    loginWithEmail(email:string, password:string) {
        return this._http
            .post<any>(this.authTenantApiUrl + `/login`, {email, password})
            .pipe(
                map(user => {
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                    this.currentUserSubject.next(user.data);
                    return user;
                })
            );
    }
    sendOtpToEmail(email: string) {
        return this._http
            .post<any>(this.authTenantApiUrl + `/send-email-opt`, {email})
    }
    verifyOtpFromEmail(opt:any): Observable<User> {
        return this._http
            .post<any>(this.authTenantApiUrl + `/email-login`, {opt})
            .pipe(
                map(user => {
                    const newUser: any = {...user.data.user, role: user.data.role, token: user.data.token};
                    localStorage.setItem('currentUser', JSON.stringify(newUser));
                    this.currentUserSubject.next(newUser);
                    return user;
                })
            );
    }



    sendOtpToStudentEmailLogin(email: string) {
        return this._http
            .post<any>(this.authTenantApiUrl + `/send-login-otp-student`, {email})
    }
    sendOtpToTeacherEmailLogin(email: string) {
        return this._http
            .post<any>(this.authTenantApiUrl + `/send-register-otp-teacher`, {email})
    }
    studentLoginByOtp(body:{email:string,otp:string}) {
        return this._http
            .post<any>(this.authTenantApiUrl + `/login-student-by-otp`, body)
            .pipe(
                map(user => {
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                    this.currentUserSubject.next(user.data);
                    return user;
                })
            );
    }
    teacherLoginByOtp(body:{email:string,otp:string}) {
        return this._http
            .post<any>(this.authTenantApiUrl + `/login-teacher-by-otp`, body)
            .pipe(
                map(user => {
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                    this.currentUserSubject.next(user.data);
                    return user;
                })
            );
    }
    sendOtpToStudentEmailRegister(body:any) {
        return this._http
            .post<any>(this.authTenantApiUrl + `/send-register-otp-student`, body)
    }
    sendOtpToTeacherEmailRegister(body:any) {
        return this._http
            .post<any>(this.authTenantApiUrl + `/send-register-otp-teacher`, body)
    }

    studentRegisterByOtp(body:{email:string,otp:string}) {
        return this._http
            .post<any>(this.authTenantApiUrl + `/register-student-by-otp`, body)
            .pipe(
                map(user => {
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                    this.currentUserSubject.next(user.data);
                    return user;
                })
            );
    }
    teacherRegisterByOtp(body:{email:string,otp:string}) {
        return this._http
            .post<any>(this.authTenantApiUrl + `/register-teacher-by-otp`, body)
            .pipe(
                map(user => {
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                    this.currentUserSubject.next(user.data);
                    return user;
                })
            );
    }
    /**
     * User logout
     *
     */
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        // notify
        this.currentUserSubject.next(null);
    }
}
